import React from 'react';
import './hero.scss';



const Hero = () => (
  <div className="hero">
    <div className="hero-img"></div>
  </div>
);



export default Hero;